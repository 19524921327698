import { render, staticRenderFns } from "./professional-tree.vue?vue&type=template&id=55b15596"
import script from "./professional-tree.vue?vue&type=script&lang=ts"
export * from "./professional-tree.vue?vue&type=script&lang=ts"
import style0 from "./professional-tree.vue?vue&type=style&index=0&id=55b15596&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\nimbusworkspace\\nimbus-web-2\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('55b15596')) {
      api.createRecord('55b15596', component.options)
    } else {
      api.reload('55b15596', component.options)
    }
    module.hot.accept("./professional-tree.vue?vue&type=template&id=55b15596", function () {
      api.rerender('55b15596', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/dashboard/config/professional-tree.vue"
export default component.exports