
import { ICRUDQ } from '@common-src/model/interface';
import { post, del } from './request';

const URL_PATH = `${WORKORDER_BASE_REQUEST_PATH}/professional`;

class ProfessionalService implements ICRUDQ<any, any> {
    async create(model: any):Promise<any> {
        throw new Error('Not implement.');
    }

    async retrieve(modelId: string):Promise<any> {
        throw new Error('Not implement.');
    }

    async update(model: any):Promise<any> {
        const url = URL_PATH;
        const params = model.toService ? model.toService() : model;
        const res = await post(url, params);
        return res;
    }

    async delete(model: any):Promise<any> {
        const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: any):Promise<any> {
        throw new Error('Not implement.');
    }
}

export default new ProfessionalService();
