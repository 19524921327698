






















































import { Component } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import ProfessionalService from '@common-src/service/professional';
import CommonService from '@common-src/service/common';
import { generateUUID } from '@common-src/utils/base_util';

@Component
export default class ProfessionalTreeComponent extends BaseComponent {
    treeData: Array<any> = null;
    treeExpandedKeys: Array<string> = [];
    searchValue: string = '';
    loading: boolean = true;

    created() {
        CommonService.getProfessionalTree().then(res => {
            this.treeData = _.map(res, item => {
                return this.initTreeData(item, 1);
            });
        }).catch(err => {
            $log.error(err);
        }).finally(() => {
            this.loading = false;
        });
    }

    /**
     * 初始化树结构数据
     */
    initTreeData(treeItem, level) {
        treeItem.scopedSlots = { title: 'custom' };
        treeItem.uuid = treeItem.key = generateUUID();
        // treeItem.key = treeItem.id;
        treeItem.level = level;
        treeItem.isEdit = false; // 新增/编辑状态
        treeItem.btnLoading = false;
        if (treeItem.children && treeItem.children.length > 0) {
            treeItem.children = _.map(treeItem.children, child => {
                child.parent = treeItem;
                return this.initTreeData(child, level + 1);
            });
        } else {
            treeItem.children = [];
        }
        this.treeExpandedKeys.push(treeItem.uuid);
        return treeItem;
    }

    initSearchExpandedKeys(treeItem) {
        if (treeItem.title.indexOf(this.TrimSearchValue) > -1) {
            this.getParentKey(treeItem);
        }
        if (treeItem.children && treeItem.children.length > 0) {
            _.forEach(treeItem.children, item => {
                this.initSearchExpandedKeys(item);
            });
        }
    }
    getParentKey(treeItem) {
        if (treeItem && treeItem.parent) {
            this.treeExpandedKeys.push(treeItem.parent.uuid);
            this.getParentKey(treeItem.parent);
        }
    }

    onSearchValueChange(e) {
        if (!this.TrimSearchValue) {
            return;
        }
        this.treeExpandedKeys = [];
        _.forEach(this.treeData, item => {
            if (item.title.indexOf(this.TrimSearchValue) > -1) {
                this.getParentKey(item.parent);
            }
            this.initSearchExpandedKeys(item);
        });
    }

    get TrimSearchValue() {
        return _.trim(this.searchValue);
    }

    /**
     * 添加点击事件
     */
    addClick(treeItem: any) {
        const uuid = generateUUID();
        if (treeItem) {
            // if (!treeItem.children) {
            //     treeItem.children = [];
            // }
            treeItem.children.unshift({
                key: uuid,
                pId: treeItem.id,
                parent: treeItem,
                title: '',
                level: treeItem.level + 1,
                isEdit: true,
                btnLoading: false,
                scopedSlots: { title: 'custom' },
                uuid,
                children: []
            });
            this.treeExpandedKeys.push(treeItem.uuid);
        } else {
            this.treeData.unshift({
                key: uuid,
                title: '',
                level: 1,
                isEdit: true,
                btnLoading: false,
                scopedSlots: { title: 'custom' },
                uuid,
                children: []
            });
        }
        this.focusFirstInput();
    }

    /**
     * 编辑点击事件
     */
    editClick(treeItem) {
        const currentTreeItem = this.findTreeItem(treeItem.uuid, this.treeData);
        if (currentTreeItem) {
            currentTreeItem.isEdit = true;
            this.focusFirstInput();
        }
    }

    /**
     * 保存点击事件
     */
    saveClick(treeItem) {
        const trimTitle = _.trim(treeItem.title);
        if (!trimTitle) {
            this.showMessageWarning('请输入专业名称');
            return;
        }
        const hasSameName: boolean = !!_.find(_.filter(_.get(treeItem, 'parent.children'), item => item.id), item => item.title === trimTitle);
        if (hasSameName) {
            this.showMessageWarning('名称重复, 请换一个...');
            return;
        }
        const currentTreeItem = this.findTreeItem(treeItem.uuid, this.treeData);
        if (currentTreeItem) {
            currentTreeItem.title = trimTitle;
            currentTreeItem.btnLoading = true;
        }
        const param: any = {
            id: treeItem.id,
            level: treeItem.level,
            name: trimTitle,
            parentId: treeItem.pId
        };
        return ProfessionalService.update(param).then(res => {
            if (currentTreeItem) {
                currentTreeItem.id = _.get(res, 'id');
                currentTreeItem.isEdit = false;
            }
        }).catch(err => {
            $log.error(err);
        }).finally(() => {
            if (currentTreeItem) {
                currentTreeItem.btnLoading = false;
            }
        });
    }

    /**
     * 取消点击事件
     */
    cancelClick(treeItem) {
        if (treeItem.id) {
            const currentTreeItem = this.findTreeItem(treeItem.uuid, this.treeData);
            if (currentTreeItem) {
                currentTreeItem.isEdit = false;
            }
        } else {
            if (treeItem.parent) {
                const currentIndex = _.findIndex(treeItem.parent.children, (item: any) => item.uuid === treeItem.uuid);
                treeItem.parent.children.splice(currentIndex, 1);
            } else {
                const currentIndex = _.findIndex(this.treeData, (item: any) => item.uuid === treeItem.uuid);
                this.treeData.splice(currentIndex, 1);
            }
        }
    }

    /**
     * 删除点击事件
     */
    deleteClick(treeItem) {
        ProfessionalService.delete(treeItem).then(res => {
            if (treeItem.parent) {
                const currentIndex = _.findIndex(treeItem.parent.children, (item: any) => item.id === treeItem.id);
                treeItem.parent.children.splice(currentIndex, 1);
            } else {
                const currentIndex = _.findIndex(this.treeData, (item: any) => item.id === treeItem.id);
                this.treeData.splice(currentIndex, 1);
            }
        }).catch(err => {
            $log.error(err);
        }).finally(() => {
        });
    }

    /**
     * 通过id，寻找到树的一个节点
     * 添加/修改都要操作这个节点的引用，否则页面不会更新
     */
    findTreeItem(uuid: string, children) {
        for (let index = 0; index < children.length; index++) {
            const item = children[index];
            if (item.uuid === uuid) {
                return item;
            }
            if (item.children && item.children.length > 0) {
                const result = this.findTreeItem(uuid, item.children);
                if (result) {
                    return result;
                }
            }
        }
    }
    focusFirstInput() {
        this.$nextTick(() => {
            try {
                // @ts-ignore
                document.getElementsByClassName('tree-item-input-name')[0].focus();
            } catch (e) {
                console.log(e);
            }
        });
    }
}
